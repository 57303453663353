import React, { useRef } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import GetStarted from '../components/GetStarted';
import Tabs from '../components/Tabs/Tabs';
import FAQ from '../components/FAQ';
import HeroLogoSlider from '../components/Sliders/HeroLogoSlider/HeroLogoSlider';
import PartnerQuoteSlider from '../components/Sliders/PartnerQuoteSlider/PartnerQuoteSlider';
import LightStatsSection from '../components/Sections/StatsSection/LightStatsSection';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';

import {
  faqData,
  checkoutOptionsData,
  heroLogoData,
  glossaryCards,
  heroSliderSettings,
  quotes,
  gatewatSuiteData,
} from '../content/upi-payment';
import '../styles/upi-payment.scss';
import { tabChange } from '../components/Tabs/utlis';

const selector = 'video-tabs';
function UpiPayementPage({ ...rest }) {
  const container = useRef();

  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchants/login?source-action=UPI%20PG&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=UPI%20PG&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="UPI Payment Gateway by Cashfree Payments"
        description="Try Cashfree’s UPI Payment Gateway to collect UPI payments easily. Supports PhonePe, Google Pay, Paytm, BHIM, etc. Enjoy highest success rates."
        url="https://www.cashfree.com/upi-payment-gateway/"
        keywords={[
          'UPI Payment Gateway',
          'Free UPI Payment Gateway',
          'UPI Payment',
          'UPI Gateway',
          'upi payment gateway api',
          'upi payment api',
          'upi payment gateway free',
          'upi transaction',
          'upi code',
          'offline upi payment',
          'upi payment app',
          'upi api',
          'upi money transfer',
        ]}
      />

      <section
        className="relative section-padding bg-cf-primary upi-payment-hero-section overflow-hidden text-white"
        ref={container}>
        <div className="container relative">
          <div className="flex flex-wrap flex-col lg:flex-row lg:flex-nowrap w-full lg:w-[100%] lg:mb-[20px]">
            <div className="relative z-[1] w-full lg:mt-[70px]">
              <h1 className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                UPI Payment Gateway
              </h1>
              <div className="mb-5 text-xl md:text-2xl font-semibold max-w-[285px] md:max-w-[600px] font-heading">
                <h2 className="inline">Collect UPI Payment</h2>s easily from
                your customers
              </div>
              <div className="text-opacity-80 mb-[30px] max-w-[620px] text-[16px] leading-[24px] md:text-2.5md">
                Accept payments from any UPI app. Choose from a range of{' '}
                <h2 className="inline font-body">UPI integration</h2> for the
                desired payment flow and achieve highest{' '}
                <h2 className="inline font-body whitespace-nowrap md:whitespace-normal">
                  UPI success rates
                </h2>
                .
              </div>

              <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-start mb-[16px] md:mb-[48px] lg:mb-10">
                <a
                  className="button button-green w-full md:w-auto md:px-[28px] mb-[16px] md:mb-0 md:mr-[16px]"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=UPI%20PG&action=Sign%20Up&button-id=StartNow_Hero">
                  Create Account <span className="chevron" />
                </a>
                <a
                  href="https://www.cashfree.com/contact-sales?source-action=UPI%20PG&action=Contact%20Sales&button-id=ContactSales_Hero"
                  className="button button-purple border border-white bg-transparent w-full md:w-auto md:px-[28px] mb-[16px] md:mb-0">
                  Contact Sales <span className="chevron" />
                </a>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full lg:w-[110%] lg:max-w-[110%] inset-video relative border-none z-10 lg:left-[15%] hidden lg:block">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10"
                  poster="/img/upi-payment/poster.png"
                  width="1496"
                  height="1100"
                  mp4Src="/img/upi-payment/hero-video.mp4"
                  webmSrc="/img/upi-payment/hero-video.webm"
                  fallBack="#6934d4"
                  useFallBack
                />
              </div>
              <div className="w-full inset-video relative border-none z-10 lg:left-[15%]  lg:hidden">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10"
                  poster="/img/upi-payment/poster-mb.png"
                  width="1496"
                  height="1100"
                  mp4Src="/img/upi-payment/hero-video-mb.mp4"
                  webmSrc="/img/upi-payment/hero-video-mb.webm"
                  fallBack="#6934d4"
                  useFallBack
                />
              </div>
            </div>
          </div>
        </div>
        <section className="logo-container pt-[56px] lg:pt-0">
          <div className="relative overflow-hidden z-10 container">
            <div className="mb-[18px] md:mb-10 text-center relative z-[1] text-2md font-semibold">
              Supported UPI Apps
            </div>
            <HeroLogoSlider
              data={heroLogoData}
              imgUrl="/img/upi-payment/logos"
              className="pl-[10px]"
              itemClassName="mx-[25px] md:mx-[36px] flex items-center align-center h-[48px]"
              settings={heroSliderSettings}
            />
          </div>
        </section>
      </section>
      <LightStatsSection />
      <div className="container">
        <div className="py-[24px] md:py-[56px] relative rounded  bg-cf-hero upi-explore-section overflow-hidden my-[48px] md:my-[96px]">
          <div className="flex flex-col sm:flex-row md:justify-center">
            <img
              src="/img/upi-payment/upi.svg"
              className="mx-6 w-28 sm:w-[120px] mb-4 sm:mb-0 relative z-[1]"
              alt=""
            />
            <p className="mx-6 sm:ml-7 max-w-[910px] text-white md:text-2.5md leading-7 relative z-[1]">
              Unified Payments Interface (UPI) is fast, easy and secure. UPI has
              become the most preferred means by consumers in India for merchant
              payments with a massive 56% share among all payment&nbsp;modes.
            </p>
          </div>
        </div>
      </div>
      <div className="pb-[48px] md:pb-[96px] upi-payment-dropdown with-bg">
        <div className="container">
          <div className="text-shs md:text-shl font-semibold max-w-[350px] sm:max-w-[610px]  mb-6 md:mb-8 font-heading">
            Offer multiple checkout options with{' '}
            <h2 className="inline">UPI Payment Gateway</h2>
          </div>
          <Tabs
            selector={selector}
            data={checkoutOptionsData}
            dropDown
            timerAutoPlay
            onChange={() => tabChange(selector)}
            dropDownFontSize="text-[16px]"
          />
        </div>
      </div>

      <section className="p-section-2 bg-cf-light-grey overflow-hidden">
        <div className="container">
          <div className="flex flex-col md:items-center mb-[60px]">
            <div className="text-sm font-semibold text-cf-primary pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
              Hear from our CUSTOMERS
            </div>
            <div className="font-heading md:text-center text-2lg leading-[39px] md:text-shl font-semibold md:mx-auto max-w-[350px] sm:max-w-[650px] mb-6">
              Indian businesses trust us with their{' '}
              <h2 className="inline">UPI payment</h2>&nbsp;needs
            </div>
            <a
              className="button button-green w-full md:w-auto wide md:mb-0"
              href="https://merchant.cashfree.com/merchants/signup?source-action=UPI%20PG&action=Sign%20Up&button-id=StartNow_HearFromOurCustomers">
              Create Account <span className="chevron" />
            </a>
          </div>
          <PartnerQuoteSlider data={quotes} />
        </div>
      </section>
      <section className="p-section-3 overflow-hidden upi-payment-dropdown">
        <div className="container">
          <div className="text-shs md:text-shl font-semibold max-w-[750px]  mb-6 md:mb-8 font-heading">
            <h2 className="inline">UPI Payment Gateway</h2> suite tailor-made
            for your business needs
          </div>

          <Tabs
            data={gatewatSuiteData}
            dropDown
            timerAutoPlay
            dropDownFontSize="text-[16px]"
          />
        </div>
      </section>

      <section className="p-section-2 bg-cf-dark text-white other-features-section upi-payment overflow-hidden">
        <div className="container relative z-10">
          <div className="text-shs md:text-shl font-semibold mb-[32px] md:mb-16 font-heading text-center">
            UPI glossary
          </div>

          <div className="flex flex-wrap flex-row lg:mx-[-19px] lg:mt-[-19px]">
            {glossaryCards.map((f) => (
              <div
                className="w-full lg:w-1/3 self-stretch pb-[16px] last:pb-0 lg:last:pb-[19px] lg:p-[19px] text-2.5sm md:text-md"
                key={f.id}>
                <div className=" bg-white bg-opacity-[0.07] min-h-[267px] md:min-h-0 md:h-full p-[24px] md:pl-6 md:pr-8 md:py-6 rounded border border-opacity-10 border-white flex flex-col ">
                  <div className="w-[40px] h-[40px] md:w-[48px] md:h-[48px] flex justify-center items-center">
                    <img
                      src={f.src}
                      alt={f.title}
                      loading="lazy"
                      style={{ maxWidth: f.maxWidth }}
                      className="w-full  md:!max-w-full"
                    />
                  </div>
                  <div className="font-semibold pt-[12px] pb-[8px] md:pt-[16px] text-[16px] text-2md">
                    {f.title}
                  </div>
                  <p className="text-cf-cold-purple text-2.5sm">{f.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div className="container general-faqs">
          <FAQ
            open="0"
            data={faqData}
            supportLinkHref="https://www.cashfree.com/help/hc"
          />
        </div>
      </section>
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=UPI%20PG&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=UPI%20PG&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}

export default UpiPayementPage;
