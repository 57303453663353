import React from 'react';
import CheckList from '../components/CheckList/CheckList';
import TabPanel from '../components/Tabs/TabPanel';

const headingClass = 'mb-2 md:mb-5';
const contentBodyMarginClass = 'mb-1 md:mb-8 max-w-[500px]';
const imageClassName = 'w-full md:pr-[18px]';

const contentClassName = 'md:pl-[19px] mt-0 lg:pt-[60px] md:pt-[30px]';
const mobileVisibleHeading = false;
const alignStart = true;
export const checkoutOptionsData = [
  {
    key: 0,
    heading: 'UPI Intent flow',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={alignStart}
          headingClass={headingClass}
          isVideo
          img={{
            src: '/img/upi-payment/upioptions-1.mp4',
            placeholder: '/img/upi-payment/upioptions-1.png',
            width: '1220',
            height: '934',
          }}
          heading="UPI Intent flow"
          headingType="div"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Simplify <h2 className="inline font-body">UPI payments</h2> for
                your customer by automatically opening UPI apps installed in
                customer’s mobile for faster checkout.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=UPI%20PG&action=Sign%20Up&button-id=StartNow_UPIIntentFlow">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'UPI Dynamic QR code',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={alignStart}
          headingClass={headingClass}
          isVideo
          img={{
            src: '/img/upi-payment/upioptions-2.mp4',
            placeholder: '/img/upi-payment/upioptions-2.png',
            width: '1220',
            height: '934',
          }}
          heading="UPI Dynamic QR code"
          headingType="div"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                <h2 className="inline font-body">Generate QR code</h2> on your
                website checkout page and let customers scan and pay using any
                UPI app.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=UPI%20PG&action=Sign%20Up&button-id=StartNow_UPIDynamicQRcode">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: 'Whatsapp UPI Links',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={alignStart}
          headingClass={headingClass}
          isVideo
          img={{
            src: '/img/upi-payment/upioptions-3.mp4',
            placeholder: '/img/upi-payment/upioptions-3.png',
            width: '1220',
            height: '934',
          }}
          heading="Whatsapp UPI Links"
          headingType="div"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                <h2 className="inline font-body">Generate UPI link</h2> and
                share with customers over WhatsApp and get paid instantly.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=UPI%20PG&action=Sign%20Up&button-id=StartNow_WhatsappUPILinks">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 3,
    heading: 'Collect UPI flow',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={alignStart}
          headingClass={headingClass}
          isVideo
          img={{
            src: '/img/upi-payment/upioptions-4.mp4',
            placeholder: '/img/upi-payment/upioptions-4.png',
            width: '1220',
            height: '934',
          }}
          heading="Collect UPI flow"
          headingType="div"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Send a payment collect request to the customer’s phone number or
                UPI ID and accept payments via UPI.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=UPI%20PG&action=Sign%20Up&button-id=StartNow_CollectUPIFlow">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
];

export const faqData = [
  {
    id: 0,
    q: 'What is UPI Payment?',
    a: (
      <>
        <p>
          UPI or Unified Payment Interface is the payment technology developed
          by National Payments Corporation of India (NPCI), using the IMPS
          infrastructure. An individual or business having a mobile number
          linked to a bank account can generate a UPI VPA and make payments to
          another UPI ID or bank account.
        </p>
        <p>
          BHIM was the first UPI app launched. Later, other payment players like
          Google Pay, PhonePe, Paytm entered the market and allowed UPI payments
          directly to a registered mobile number or UPI VPA, and also by
          scanning a QR code using a smartphone.
        </p>
      </>
    ),
  },
  {
    id: 1,
    q: 'What is Cashfree UPI Payment Gateway?',
    a: (
      <p>
        When you sign up with Cashfree Payments, you get access to an entire
        suite of UPI payments infrastructure for all your payment collection and
        reconciliation needs.
      </p>
    ),
  },
  {
    id: 2,
    q: 'What is the UPI transaction limit?',
    a: (
      <>
        <p>
          The UPI transaction limit is ₹ 1 lakh per transaction, as set by NPCI.
          The UPI transaction limit per day varies from bank to bank since NPCI
          has not issued any regulation on this aspect. For example: at SBI, the
          limit per day is ₹ 1000, while at Bank of Baroda, it is ₹ 50,000.
        </p>
        <p>
          Additionally, for peer to peer, the UPI payment limit is 10
          transactions, but this is not applicable for peer to merchant UPI
          payments.
        </p>
      </>
    ),
  },
  {
    id: 3,
    q:
      'What are the charges for the merchants for processing payments via UPI?',
    a: (
      <p>
        Government of India has clearly issued a norm to not levy any charges on
        UPI transactions for both the merchants and the customers. UPI
        transactions are zero charges and can be processed without any TDR
        deductions.
      </p>
    ),
  },
  {
    id: 4,
    q: 'How to integrate with multiple UPI apps to collect UPI payments?',
    a: (
      <p>
        As a merchant, you don’t have to integrate with different UPI apps
        separately. All you have to do is integrate Cashfree Payments UPI
        Payment Gateway with your website or mobile app to offer 20+ UPI apps to
        your customers. We’ve built direct integrations with PhonePe, Google
        Pay, Paytm, BHIM, Amazon Pay and other popular UPI apps.
      </p>
    ),
  },
];

export const gatewaySuite = [
  {
    id: 0,
    image: '/img/partner/gaurav.png',
    companyLogo: {
      src: '/img/partner/prinknest.png',
      width: '58',
      height: '41',
      alt: 'Prinknest Technology LLP',
    },
    name: 'Gaurav Mishra',
    designation: 'CEO, Prinknest Technology LLP',
    link: '#!',
    quote:
      'We are working as a partner with Cashfree for a while, and offering their solutions to our clients. Cashfree has products for all your payment collection related requirements.  We & our clients both are happy on their fast settlement, costing & payment related support.',
  },
  {
    id: 1,
    image: '/img/partner/anand.png',
    companyLogo: {
      src: '/img/partner/nileegame.png',
      width: '103',
      height: '20',
      alt: 'Nilee Games & Future Technologies Pvt. Ltd.',
    },
    name: 'Anand Jha',
    designation: 'Director, Nilee Games & Future Technologies Pvt. Ltd.',
    link: '#!',
    quote:
      'Cashfree really helped us to focus on our core business rather than worrying about payment issues and service interruptions. Their commercials are very competitive within the market. We are using Cashfree’s PG and Payout product. Their support staff are very attentive and ensure they understand our requirements exactly and deliver.',
  },
  {
    id: 2,
    image: '/img/partner/vijay.png',
    companyLogo: {
      src: '/img/partner/baagdi.png',
      width: '72',
      height: '29',
      alt: 'Baagdi Solutions',
    },
    name: 'Rashtra Vijay Choudhary',
    designation: 'Managing Director, Baagdi Solutions',
    link: '#!',
    quote:
      'Here in baagdi we were looking for a new payment gateway provider, we did some research and looked around at the different pricing and type of service offerings. We found Cashfree to be the best in both,and decided to partner. Our daily interaction with the product is all done via the internet. and almost all payments are done automatically through the website using Cashfree. Support team is very dedicated. Now many of our clients have started using Cashfree.',
  },
  {
    id: 3,
    image: '/img/partner/jun.png',
    companyLogo: {
      src: '/img/partner/advance.png',
      width: '93',
      height: '11',
      alt: 'Advance.Al',
    },
    name: 'Jun Zhang',
    designation: 'India Head of Partnership & Strategy, Advance.Al',
    link: '#!',
    quote:
      "Cashfree has an NBFC-specific payment processing suite which easily integrates ADVANCE.AI's KYC and anti-fraud technology that leverages big data and machine learning to deliver best-in class-products. This collaboration will help us tap the Indian market and meet the demand by Indian fintechs and NBFCs for easier and faster processing of loan disbursals.",
  },
  {
    id: 4,
    image: '/img/partner/akash.png',
    companyLogo: {
      src: '/img/partner/billeasy.png',
      width: '55',
      height: '32',
      alt: 'Billeasy',
    },
    name: 'Akash Patil',
    designation: 'CEO, Billeasy',
    link: '#!',
    quote:
      'Cashfree enables us to provide easy payment solutions for many of our online and offline clientele. Their response towards account setup and other requests have been very prompt. Their product offering is strong and reliable across channels.',
  },
];

export const glossaryCards = [
  {
    id: 0,
    src: '/img/upi-payment/glossary-1.svg',
    title: 'UPI',
    text: (
      <>
        Unified Payments Interface (UPI) is real-time, secure payment system
        developed by National Payments Corporation of India (NPCI). It helps in
        immediate money transfer easily through mobile device 24*7 and
        365&nbsp;days.
      </>
    ),
  },
  {
    id: 1,
    src: '/img/upi-payment/glossary-2.svg',
    title: 'UPI ID',
    text: (
      <>
        UPI ID is a unique identifier to each UPI linked bank account and are
        used to facilitate payments between customers and merchants. It provides
        a Virtual Payments Address (UPI VPA) to enter when transferring money to
        the account linked to a UPI&nbsp;ID.
      </>
    ),
  },
  {
    id: 2,
    src: '/img/upi-payment/glossary-3.svg',
    title: 'UPI PIN',
    text: (
      <>
        UPI PIN is a unique 4 or 6 digit pin that the user has to set to
        authenticate every payment made via UPI&nbsp;apps.
      </>
    ),
  },
];

export const heroLogoData = [
  {
    id: 0,
    src: 'phonepe.png',
    alt: 'PhonePe',
    width: '312',
    height: '93',
  },
  {
    id: 1,
    src: 'googlepay.png',
    alt: 'Google Pay',
    width: '375',
    height: '67',
  },
  {
    id: 2,
    src: 'paytm.png',
    alt: 'Paytm',
    width: '243',
    height: '78',
  },
  {
    id: 3,
    src: 'bhim.png',
    alt: 'Bhim',
    width: '304',
    height: '99',
  },
  {
    id: 4,
    src: 'amazonpay.png',
    alt: 'Amazon Pay',
    width: '177',
    height: '150',
  },
  {
    id: 5,
    src: 'whatsapp.png',
    alt: 'Whatsapp',
    width: '359',
    height: '84',
  },
  {
    id: 6,
    src: 'tataneu.png',
    alt: 'TATA NEU',
    width: '192',
    height: '133',
  },
  {
    id: 7,
    src: 'andmore.png',
    alt: 'And more',
    width: '232',
    height: '36',
  },
];

export const heroSliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 0,
  swipe: false,
  pauseOnHover: false,
  draggable: false,
  centerMode: false,
  speed: 2000,
  slidesToShow: heroLogoData.length,
  variableWidth: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 500,
      settings: {
        rtl: true,
        draggable: false,
        autoPlay: true,
        autoplay: true,
        speed: 2000,
        swipe: false,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        slidesToShow: 2.8,
        infinite: true,
        centerMode: false,
        variableWidth: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        rtl: true,
        draggable: false,
        autoPlay: true,
        autoplay: true,
        speed: 2000,
        swipe: false,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        slidesToShow: 5,
        infinite: true,
        centerMode: false,
        variableWidth: true,
      },
    },
    {
      breakpoint: 990,
      settings: {
        rtl: true,
        draggable: false,
        autoPlay: true,
        autoplay: true,
        speed: 2000,
        swipe: false,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        slidesToShow: 6,
        infinite: true,
        centerMode: false,
        variableWidth: true,
      },
    },
  ],
};

export const quotes = [
  {
    id: 0,
    image: '/img/upi-payment/amritansu-nanda.jpg',
    companyLogo: {
      src: '/img/upi-payment/zepto.png',
      width: '83',
      height: '28',
      alt: 'Zepto',
    },
    name: 'Amritansu Nanda',
    designation: 'Chief Marketing Officer, Zepto',
    quote:
      'As a service that prioritises convenience, our association with Cashfree Payments enables a seamless UPI payment experience for our customers.',
  },
  {
    id: 1,
    image: '/img/upi-payment/parasar-sarma.jpg',
    companyLogo: {
      src: '/img/upi-payment/wakefit.png',
      width: '91',
      height: '39',
      alt: 'Wakefit',
    },
    name: 'Parasar Sarma',
    designation: 'Vice President of Growth, Wakefit',

    quote:
      'We saw that the UPI success percentages with other payment aggregators were a little low. With Cashfree’s Payment Gateway, we experienced a tremendous increase in our success rates in UPI. This has helped us increase our customer conversion ratio.',
  },
];

export const gatewatSuiteData = [
  {
    key: 0,
    heading: <h2 className="inline font-body">UPI Payment Links API</h2>,
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={alignStart}
          headingClass={headingClass}
          img={{
            src: '/img/upi-payment/upi-gateway-1.png',
            width: '610',
            height: '460',
          }}
          heading="UPI Payment Links API"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                <CheckList
                  className="mb-0"
                  data={[
                    {
                      id: 0,
                      text: 'APIs that are easy to integrate',
                    },
                    {
                      id: 1,
                      text: 'Embed links on your website or mobile app',
                    },
                    {
                      id: 2,
                      text:
                        'Allow your users to pay without entering the amount',
                    },
                  ]}
                  itemMargin="mb-4 md:mb-3"
                />
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://docs.cashfree.com/reference/createpaymentlink"
              target="_blank"
              rel="noreferrer">
              Check documentation <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'Dynamic QR code',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={alignStart}
          headingClass={headingClass}
          img={{
            src: '/img/upi-payment/upi-gateway-2.png',
            width: '610',
            height: '460',
          }}
          heading="Dynamic QR code"
          headingType="div"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                <CheckList
                  className="mb-0"
                  data={[
                    {
                      id: 0,
                      text:
                        'Generate and display dynamic QR code on your website or softPOS app',
                    },
                    {
                      id: 1,
                      text:
                        'Use our APIs and make it easy for your customers to pay',
                    },
                  ]}
                  itemMargin="mb-4 md:mb-3"
                />
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              target="_blank"
              rel="noreferrer"
              href="https://docs.cashfree.com/reference/create-dynamic-qr-code">
              Check documentation <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: 'Static QR code',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={alignStart}
          headingClass={headingClass}
          img={{
            src: '/img/upi-payment/upi-gateway-3.png',
            width: '610',
            height: '460',
          }}
          heading="Static QR code"
          headingType="div"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Manage your offline payments collection easily by generating QR
                code from your desktop or your softPOS app.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/soft-pos/">
              Learn more <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 3,
    heading: (
      <span>
        <h2 className="inline font-body">Virtual UPI ID</h2> for B2B Payments
      </span>
    ),
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={alignStart}
          headingClass={headingClass}
          img={{
            src: '/img/upi-payment/upi-gateway-4.svg',
            width: '610',
            height: '460',
          }}
          headingType="div"
          heading={
            <>
              <h2 className="inline font-body">Virtual UPI ID</h2> for B2B
              Payments
            </>
          }
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                <CheckList
                  className="mb-0"
                  data={[
                    {
                      id: 0,
                      text:
                        'Create unique virtual UPI IDs assigned to every payer',
                    },
                    {
                      id: 1,
                      text:
                        'Automatically reconcile all the UPI payments received from different customers',
                    },
                    {
                      id: 2,
                      text:
                        'Allow your users to pay without entering the amount',
                    },
                  ]}
                  itemMargin="mb-4 md:mb-3"
                />
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/auto-e-collect/">
              Learn more <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 4,
    heading: 'UPI AutoPay',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={alignStart}
          headingClass={headingClass}
          img={{
            src: '/img/upi-payment/upi-gateway-5.png',
            width: '610',
            height: '460',
          }}
          heading="UPI AutoPay"
          headingType="div"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Automate recurring payments collection upto ₹15,000 with one
                time UPI mandate authorization and grow your subscription
                business.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/upi-autopay/">
              Learn more <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 5,
    heading: 'UPI one-time mandate',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={alignStart}
          headingClass={headingClass}
          img={{
            src: '/img/upi-payment/upi-gateway-6.png',
            width: '610',
            height: '460',
          }}
          heading="UPI one-time mandate"
          headingType="div"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Hold customer payments via one-time mandate{' '}
                <span className="whitespace-nowrap">pre-authorization</span>
                from any UPI app. Without manual intervention, you can
                auto-debit from your customer’s accounts on the
                authorized&nbsp;date.
              </div>
            </>
          }
          footer={null}
        />
      </div>
    ),
  },
];
